@import 'components/_mixins';
@import 'components/_variables';

@import 'components/_layout';
@import 'components/_dropdown';
@import 'components/_popup';
@import 'components/_typography';

body {
  margin: 0;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}
