.clearfix {
  zoom: 1; // For IE 6/7 (trigger hasLayout)

  &:before, &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.border-radius (@radius: 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -moz-background-clip: padding;
}

//Different radius each side
.border-radius(@top-left,  @top-right,  @bottom-left,  @bottom-right)
{
  border-top-left-radius: @top-left;
  border-top-right-radius: @top-right;
  border-bottom-right-radius: @bottom-right;
  border-bottom-left-radius: @bottom-left;
}

.border-top-radius(@radius: 5px) {
  border-top-left-radius: @radius;
  border-top-right-radius: @radius;
}


.padding(@left: 15px, @right: 15px, @top: 15px, @bottom: 15px) {
  padding-left: @left;
  padding-right: @right;
  padding-top: @top;
  padding-bottom: @bottom;
}

.margin(@left: 15px, @right: 15px, @top: 15px, @bottom: 15px){
  margin-left: @left;
  margin-right: @right;
  margin-top: @top;
  margin-bottom: @bottom;
}
.transition(@transition) {
  -webkit-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}

.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow; // iOS <4.3 & Android <4.1
  box-shadow: @shadow;
}

.bold-font() {
  font-family: Roboto-Bold;
}

.semibold-font() {
  font-family: Roboto-Medium;
}

.medium-font() {
  font-family: Roboto-Medium;
}

.regular-font() {
  font-family: Roboto-Regular;
}

.light-font() {
  font-family: Roboto-Light;
}

.lightitalic-font() {
  font-family: Roboto-LightItalic;
}

.italic-font() {
  font-family: Roboto-Italic;
}

.black-font() {
  font-family: Roboto-Black;
}

.icon {
  display: inline-block;
  background-repeat: no-repeat !important;
}

.icon-hamburger-menu {
  width: 21px;
  height: 13px;
}

.truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.droppable-border() {
  position: absolute;
  z-index: 1;
  top: 4px;
  left: 4px;
  bottom: 4px;
  right: 4px;
  border-radius: 10px;
  background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: rgb(172,213,255); stroke-width: 4; stroke-dasharray: 15 11'/></svg>");
}

.disabled {
  pointer-events: none;
  color: #ccc;
  background-color: unset;
}