.popup-wrapper {
  width: 100%;
  height: 100%;

  .modal-content {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    box-shadow: none;
    border-radius: 0;
    border: none;
  }
  .modal-title {
    cursor: default;
  }
}
