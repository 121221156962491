html, body {
  width: 100%;
  height: 100%;
  box-sizing: inherit;
  color: @gray-dark;
  font-size: 13px;
  .regular-font;
  letter-spacing: 0.3px;
  overflow: hidden;
}

body.app-body {
  min-width: 900px;
  overflow: hidden;
}

.wrapper {
  display: block;
  width: 100%;
  height: 100%;
}

.loader {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
