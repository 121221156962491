@import '_variables';

.dropdown {
  display: inline-block;
  position: relative;
  vertical-align: baseline;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-item {
  display: block;
  clear: both;
  padding: 3px 20px;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  &:hover, &:focus {
    background-color: @dropdown-item-hover-bg;
  }
  &.selected {
    padding-left: 4px;
  }
}
